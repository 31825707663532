import React from "react";

export function NotFound() {
    return (
        <main className="mx-auto container min-h-screen flex items-center">
            <div>
                <h1 className="text-6xl">Not Found</h1>
                <a className="text-blue-500 mt-6" href="/">Go Home</a>
            </div>
        </main>
    )
}