import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {NotFound} from "./pages/NotFound";
import {Home} from "./pages/Home";
import {Profile} from "./pages/Profile";
import {StripeAccount} from "./pages/StripeAccount";
import {StorePage} from "./pages/StorePage";
import {PrivacyNotice} from "./pages/PrivacyNotice";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/stores/:userId" element={<StorePage/>}/>
                <Route path="/settings" element={<Profile/>}/>
                <Route path="/privacy-notice" element={<PrivacyNotice/>}/>
                <Route path="/stripe-account" element={<StripeAccount/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
