import {doc, onSnapshot} from "firebase/firestore";
import {useCallback, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {db} from "../firebase";
import {Store} from "../../../models/models";
import {Image} from "../Image";
import svg from "../../src/app-store.svg";

export function StorePage() {
    
    const {pathname} = useLocation();
    const {userId} = useParams();
    const [data, setData] = useState<Store>();

    const connectRegister = useCallback(async () => {
        // TODO register the user, add the service to the user. If the user exists already then open the App
    }, []);
    
    useEffect(() => {
        if (userId) {
            const docRef = doc(db, 'stores', userId);
            return onSnapshot(docRef, snapshot => setData(snapshot.data() as Store));   
        }
    }, [userId]);

    useEffect(() => {

        const isIOS = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };
        if (!pathname) {
            return;
        } else if (isIOS()) {
            // launchApp(); -- not launching for now while we built this website
        } else {
            console.log('Non-iOS device detected');
        }
    }, [pathname]);

    const launchApp = useCallback(() => {
        const urlScheme = `shop-trendi:/${pathname}`;
        const storeURL = 'https://apps.apple.com/app/idYOUR_APP_ID';
        const startTime = new Date().getTime();
        const timeout = 2000;

        window.location.href = urlScheme;
        setTimeout(() => {
            const endTime = new Date().getTime();
            if (!document.hidden && endTime - startTime < timeout + 100) {
                window.location.href = storeURL;
            }
        }, timeout);
    },[pathname]);

    if (!data || !userId) {
        return null;
    }

    return (
        <main>
            <div className="mx-4 mt-4">
                <p>TRENDI</p>
            </div>
            <div className="mx-4 min-h-screen flex flex-col py-6">
                <h1 className="text-2xl">{data.displayName}</h1>
                <p className="text-gray-600 text-sm mb-6">
                    To Connect, get the iOS App
                    <br/>
                    <button onClick={launchApp}><img src={svg} alt="..."/></button>
                </p>
                <div className="space-y-12">
                    {(data.services || []).map(({images, name, minimumBudget, description}) => {
                        return (
                            <div key={name}>
                                <h1 className="text-gray-700 text-sm">{description}</h1>
                                <div className="flex overflow-x-scroll">
                                    {(images || []).map(image => <Image key={image.imageUri} imageUri={image.imageUri}/>)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </main>
    );
}
