import appstore from "../app-store.svg";

export function StripeAccount() {
    return (
        <main className="flex flex-col min-h-screen py-12 px-4 space-y-12">
            <div>
                <h1 className="font-bold">Stripe Account Setup Complete</h1>
                <p>Your stripe account has been setup. You need to download the App to continue interacting.</p>
            </div>

            <p>
                <span>Click on the App Store icon below to download:</span>
                <a href="https://testflight.apple.com/join/Usj3XSl7">
                    <img src={appstore} alt="Download from App Store"/>
                </a>
            </p>
        </main>
    );
}