import React from "react";

export function Home() {
    return (
        <main className="mx-auto container min-h-screen flex items-center">
            <div>
                <h1 className="text-6xl">Home Page</h1>
                <pre>this site is under construction</pre>
                <ul className="mt-6">
                    <li>
                        <a className="text-blue-500" href="/profile">My Profile</a>
                    </li>
                </ul>
            </div>
        </main>
    )
}