import {getDownloadURL, ref} from "firebase/storage";
import {useEffect, useState} from "react";
import {storage} from "./firebase";

export function Image({imageUri}: { imageUri: string }) {
    const [downloadURL, setDownloadURL] = useState<string>();
    useEffect(() => {
        (async function () {
            const thumbRef = ref(storage, `${imageUri}.thumb`);
            const fullRef = ref(storage, imageUri);
            try {
                const downloadURL = await getDownloadURL(thumbRef);
                setDownloadURL(downloadURL);
            } catch (e) {
                const downloadURL2 = await getDownloadURL(fullRef);
                setDownloadURL(downloadURL2);
            }
        })
        ();
    }, [imageUri]);
    if (!downloadURL) {
        return null;
    }
    return (
        <img src={downloadURL} alt="picture" className="w-32 max-w-full object-contain"/>
    );
}